@import "../../variables";

.tableFilter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }

  & > div {
    flex-grow: 1;
    padding: 0 10px;

    .MuiInputBase-root {
      width: 100%;
    }
  }
}

textarea {
  overflow: hidden;
  border: 1px solid rgb(204, 204, 204);
  padding: 20px 10px;
  border-radius: 5px;
  margin: 0px;
  resize: vertical;
  width: 100%;
  outline-color: #93c01f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  &:hover {
    border-color: #333;
  }
}
