@import "../../variables";

.ShopTile-Regional {
  margin: 1em;
  text-align: left;

  &>div {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    line-height: 120%;
  }

  svg {
    margin-right: 10px;
    color: #009fd6;
    width: 16px !important;
  }

}

.swal2-popup {
  padding: 40px;

  .MuiButton-root {
    background: $primaryColor;
  }
}

.filterbuttonsbox {
  flex-direction: column;

  .filterbuttons {
    width: 100%;
    margin: 0 0 10px 0;
  
    & > * {
      width: 100%;
    }
  }
}

@include sm {
  .filterbuttonsbox {
    flex-direction: row;

    .filterbuttons {
      width: auto;
      margin: 0 10px 0 0;
  
      & > * {
        width: auto;
      }
    }
  }
}