@import '../../variables';

.responsiveTable {
  width: 100%;
  border-spacing: 0;
  font-size: 13px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  thead {
    background-color: #ffffff;
    display: none;

    @include sm {
      display: table-header-group;
    }
  }

  .tableHead {
    cursor: pointer;
    font-family: $fontDefault;
  }

  &.sumRow {
    .tableRow:last-of-type {
      font-weight: bold;
      display: none;

      @include sm {
        display: table-row;
      }
    }
  }

  .tableRow.hiddenRow {
    background: #ffffff;
    padding: 5px;

    .entityValue {
      text-align: right;
    }

    .entityLabel {
      font-weight: bold;
    }

    .entityLabel,
    .entityValue {
      padding: 5px 10px;
    }
  }

  td {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    font-family: Arial, Helvetica, sans-serif;
  }

  tr + tr {
    td {
      border-top: 0 none;
    }
  }

  th,
  td {
    padding: 16px 16px;
    border-spacing: 0;
  }

  th {
    font-size: 13px;
    padding: 16px 16px 16px;
    border-bottom: 1px solid #93c01f;
  }

  &.benefits {
    border-top: 0 none;

    .tableHead {
      cursor: pointer;
      background-color: transparent;
      color: $primaryColor;
    }

    .tableRow.hiddenRow {
      background: transparent;
      padding: 5px;
    }

    td {
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      font-family: Arial, Helvetica, sans-serif;
    }

    th {
      font-size: 1.2em;
    }

    th,
    td {
      padding: 16px 24px;
      border-spacing: 0;
    }

    th {
      padding: 16px 24px 16px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  & .pagination-label {
    margin-right: 20px;
  }

  .paginationControls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previous,
    .next {
      padding: 5px 10px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
