@import "../../variables";

.filter {
  .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    border: 1px solid;
    padding: 8px 20px;
    .MuiButton-label {
      font-size: 1rem;
      color: #737373;
    }
  }
  .MuiInput-root.rounded-select .MuiSelect-select {
    color: #737373;
    font-family: Hiruko Pro, sans-serif;
    font-weight: 300;
  }
}
