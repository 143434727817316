@import "../../variables";

.ShopDetail {
  &-vouchers {
    & > div:first-child {
      padding-top: 0;
      margin-top: 2em;
    }
    & > div:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  &-voucher {
    border-bottom: 1px solid #ccc;
    padding-top: 2em;
    padding-bottom: 2em;

    & > button,
    & > a {
      float: right;
      position: relative;
      top: -0.6em;
      color: $primaryColor;
      border-color: $primaryColor;
      border-style: dashed;
      span {
        color: $primaryColor !important;
      }
    }

    h3 {
      color: $primaryColor;
      margin-bottom: 1.5em;
    }

    &-info {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      font-weight: 400;
    }
  }
}
