@import "../../variables";

.HeroSearch {
  width: 100%;
  margin-top: 50px;
  @include sm {
    margin-top: 80px;
  }
  @include lg {
    margin-top: 0;
  }
  h1 {
    span {
      display: block;
      font-weight: 300;
    }
  }

  & > div.HeroSearch-main {
    display: flex;
    flex-direction: row;

    input {
      font: 300 1rem/26px Hiruko Pro;
      color: #fff;
      width: 100%;
      background: none;
      outline: none;
      border: none;
      letter-spacing: 0.05rem;

      @include lg {
        font: 300 $fontSizeHeadline3 Hiruko Pro;
        padding: 0 20px;
      }
      &::placeholder {
        color: #fff;
        letter-spacing: 0.05rem;
      }
    }

    button {
      padding: 8px 40px;
      background: $primaryColor;
      border-radius: 100px;
      color: #fff;
      outline: none;
      border: none;
      margin: 1px;
      font: 400 1rem/26px Hiruko Pro;
      letter-spacing: 1px;
      cursor: pointer;
      @include lg {
        padding: 15px 70px;
        font: 400 1.3rem/26px Hiruko Pro;
      }
    }
  }

  & > div.HeroSearch-children {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 25px;

    & > * {
      margin-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      @include lg {
        margin-bottom: 0;
      }
      &:hover {
        background: #ffffff30 !important;
      }
    }

    button {
      font-size: 14px;
      color: #fff;
      border: 1px solid $primaryColor;
      border-radius: 50px;
      background: none;
      outline: none;
      padding: 10px 20px;

      &.active {
        background: $highlightColor;
        border: 1px solid $highlightColor;
        &:hover {
          background: $highlightColor !important;
          opacity: 0.8;
        }
      }
    }

    .MuiInput-root {
      border: 1px solid $primaryColor;
      border-radius: 50px;
      background: none;
      outline: none;
      padding: 0;
      color: #fff;
      min-width: 200px;
      font-size: 14px;

      & > div {
        padding: 10px 20px;
        width: 100%;
      }

      .MuiSelect-select:focus {
        background: none;
      }

      &:before,
      &:after {
        display: none;
      }

      & > svg {
        color: #fff;
        right: 12px;
      }
    }
  }
}
