@import "../../variables";

.Box {
  padding: $boxPadding;
  background: #fff;

  & > h2,
  & > h1 {
    margin-top: 0;
    font: 300 $fontSizeHeadline2 Hiruko Pro;
    letter-spacing: 0;
  }

  & > h3 {
    margin-top: 0;
    font: 300 $fontSizeHeadline3 Hiruko Pro;
    letter-spacing: 0;
    color: $primaryColor;
  }

  & > p {
    font-size: $fontSizeDefault;
    line-height: 160%;
  }

  &.Box-Border {
    border: 1px dashed #cccccc;
  }

  .MuiFormControl-root {
    width: 100%;
    .MuiFilledInput-root {
      background-color: #f7f7f7;
    }
  }
}
