@import '../../variables';

.Home {
  .hero h1 {
    width: 50%;
    @include md {
      width: 100%;
    }
  }
  .bubble {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    width: 160px;
    padding: 50px 15px;
    color: #fff;
    text-align: center;
    transform: rotate(10deg);
    z-index: 2;
    display: none;
    a {
      color: #fff;
    }
    @include md {
      right: 50px;
      transform: translateY(-130%) rotate(15deg);
      display: block;
    }
    &:before {
      z-index: -1;
      width: 100%;
      padding-top: 100%;
      display: block;
      content: '';
      border: 2px solid #fff;
      background: linear-gradient(#d10a11, #fcc200);
      position: absolute;
      top: 0;
      border-radius: 50%;
      left: 0;
    }
    &.biggerBubble {
      padding: 55px 15px;
      width: 190px;
      z-index: 1;
      display: block;
      font-size: 0.9em;
      right: 5px;
      transform: rotate(10deg);
      h3 {
        margin: 0;
        font-size: 1.5em;
      }
      @include sm {
        right: 35px;
        transform: translateY(-20%) rotate(10deg);
      }
      @include lg {
        right: 200px;
        transform: translateY(-100%) rotate(10deg);
      }
    }
  }
}
