.KpiTile {
  text-align: center;
  padding: 18px;
  position: relative;
  min-height: 50px;

  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-bottom: 1.5em;
  .inner-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h2 {
    padding-bottom: 16px;
    font-size: 1.8rem;
  }
  h3 {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.5;
    width: 100%;
  }
  p {
    margin: 0;
  }
  .divider {
    width: 50%;
    height: 2px;
    background: #fff;
    margin: 0 auto 8px auto;
  }
  .children-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    h2 {
      padding: 0;
    }
  }
  .icon {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 28px;
    height: 28px;
    &.top {
      bottom: auto;
      top: 12px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
