@import "../../variables";

.ShopTile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px dashed #ccc;
  background: #fff;
  position: relative;
  height: 100%;

  & > * {
    margin-top: 10px;
  }

  &-image-wrapper {
    width: 100%;
    padding: 0 30px;
    text-align: center;
    justify-content: center;
    margin-top: 3em;
    min-height: 85px;
  }
  &-image {
    width: 100%;
    max-width: 151px;
  }

  &-content {
    text-align: center;
    letter-spacing: 0;
    font-size: $fontSizeDefault;
    line-height: 150%;
    margin-bottom: 15px;
    margin-top: 25px;
    strong {
      color: $primaryColor;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    width: 100%;

    & > a.more {
      font-size: $fontSizeDefault;
      margin-bottom: 2em;
      margin-top: 0.5em;
      cursor: pointer;
      color: currentColor;
      text-decoration: none !important;
      border-bottom: 1px solid $primaryColor;
    }
  }
  &-content {
    width: 100%;
  }

  &-star {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $starColor;
    cursor: pointer;
  }
}
