@import "../../variables";

.Location {

  height: auto;

  .MuiSlider-root {
    color: $primaryColor;
  }

  .MuiInputBase-root {
    input {
      color: #000;
    }
  }

}
