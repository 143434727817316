@import "../../variables";

.Pagination {

    padding-top: 3em;
    text-align: center;

    span {
        margin: 0 5px;
        font-size: $fontSizeDefault;
        color: $textColor;
        padding: 5px 7px;
        cursor: pointer;

        &.active {
            background: $primaryColor;
            color: #fff;
        }

        &:hover {
            opacity: 0.5;
        }

        svg {
            position: relative;
        }
    }

}
