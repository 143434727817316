@import "../../variables";

.CashbackCounter {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(147, 192, 31, 0.9);
  color: #fff;
  font-family: Hiruko Pro, sans-serif;
  padding: 16px 48px 16px 16px;

  @include lg {
    padding: 24px 96px 24px 24px;
  }
  div {
    font-size: 1.2rem;
    @include lg {
      font-size: 1.5rem;
    }
    font-weight: 200;
    width: 100%;
  }

  span {
    display: block;
    font-size: 1.7rem;
    @include lg {
      font-size: 2rem;
    }
    font-weight: 400;
    width: 100%;
  }
}
