@import "../../variables";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $backgroundGradient;

  padding: 40px 2%;
  nav {
    display: none;
  }
  a {
    color: #fff;
    margin: 10px;
    text-decoration: none;
    &.active {
      border-bottom: 1px solid $primaryColor;
    }
  }
  @include md {
    nav {
      display: block;
    }
  }
  @include lg {
    padding: 40px 10%;
  }
}
