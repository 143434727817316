@font-face {
  font-family: "Hiruko Pro";
  src: url(/assets/fonts/hirukopro-light-a1e7e955e26af12a038e59cd89f9787eb3d9f6cbdf4ba72b66154d69315a07de.eot);
  src: url(/assets/fonts/hirukopro-light-a1e7e955e26af12a038e59cd89f9787eb3d9f6cbdf4ba72b66154d69315a07de.eot?#iefix)
      format("embedded-opentype"),
    url(/assets/fonts/hirukopro-light-38c3b258954e7b67241a28fe71159782c68003ca4671432c352ea6f05aae9971.woff)
      format("woff"),
    url(/assets/fonts/hirukopro-light-5a39b492e9d9894d3e83a5d969503a113cbec094cad1f723dbabdec90398a344.ttf)
      format("truetype"),
    url(/assets/fonts/hirukopro-light-61583e5c5e011a6a73895b9a082b0c663dbda5e8df9b5ff4242b19c64ca377ec.svg#hiruko_prolight)
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Hiruko Pro";
  src: url(/assets/fonts/hirukopro-regular-59d7de5d3d69d8c30174c92fd580a463a4e559c771959bbf0fd5399263ec86cd.eot);
  src: url(/assets/fonts/hirukopro-regular-59d7de5d3d69d8c30174c92fd580a463a4e559c771959bbf0fd5399263ec86cd.eot?#iefix)
      format("embedded-opentype"),
    url(/assets/fonts/hirukopro-regular-cc9fa2e833abc2b33b0b96d80e7bb9bb83d21b09831fe9d75dfd0ddd7ae39acf.woff)
      format("woff"),
    url(/assets/fonts/hirukopro-regular-2824b5b39003c1ff8507941863b27a42bdd729a8dd82c7b2eb8e7b8623c9d875.ttf)
      format("truetype"),
    url(/assets/fonts/hirukopro-regular-8a7b8f651fd3bc515a0968e324f0a825d690914fcf37abb3e6bc1f56de6b5f40.svg#hiruko_proregular)
      format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Hiruko Pro";
  src: url(/assets/fonts/hirukopro-bold-84ef3487885bbba953ccba2680040f265ac900f2107d15ebca1c8d57a91721b6.eot);
  src: url(/assets/fonts/hirukopro-bold-84ef3487885bbba953ccba2680040f265ac900f2107d15ebca1c8d57a91721b6.eot?#iefix)
      format("embedded-opentype"),
    url(/assets/fonts/hirukopro-bold-f4203afdeeff17afb2c07b38706e86a95856b75c1097a982db699255a1e162a5.woff)
      format("woff"),
    url(/assets/fonts/hirukopro-bold-d54d51c91b7b64a62667900ce5ab6536596bc8419f1581da790fb87eb7e3007d.ttf)
      format("truetype"),
    url(/assets/fonts/hirukopro-bold-490e15e3eed96e216c6b8a79d656d1221f282edd345d0d64fc35ca7f4efae759.svg#hiruko_probold)
      format("svg");
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: 87.5%;
}

body {
  font-size: 14px;
  font-size: 1rem;
}

.MuiButton-root .MuiButton-label {
  font-size: 1rem;
}

h1,
h2,
h3,
h4 {
  font-family: Hiruko Pro, sans-serif;
  font-weight: 300;
  letter-spacing: 0;
}

h1 {
  color: #fff;
  font-size: 2rem;
  @include md {
    font-size: $fontSizeHeadline1;
  }
}

h2 {
  color: $primaryColor;
  font-size: 1.7rem;
  @include md {
    font-size: $fontSizeHeadline2;
  }
}

h3 {
  color: currentColor;
  font-size: $fontSizeHeadline3;
}

h4 {
  color: currentColor;
  font-size: $fontSizeHeadline4;
}

[class*="Tile"] {
  h2 {
    font-weight: 400;
    color: inherit;
    margin: 0;
  }
  h3 {
    font-size: 1.2rem;
  }
}

.font-extra-small {
  font-size: $fontSizeExtraSmall;
}
.font-small {
  font-size: $fontSizeSmall;
}
