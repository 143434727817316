$breakpoint-xs: '0px';
$breakpoint-sm: '600px';
$breakpoint-md: '1140px';
$breakpoint-lg: '1280px';
$breakpoint-xl: '1920px';

@mixin xs {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin xsMax {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lgMax {
  @media (max-width: 1500px) {
    @content;
  }
}

$primaryColor: #93c01f;
$secondaryColor: #004685;
$textColor: #2b2e34;
$lightTextColor: #737373;
$highlightColor: #009fd6;
$starColor: #fcc200;
$red: #d10a11;

$errorColorLight: #f4b0a9;
$errorColorLighter: #fdf1f0;
$errorColorDarker: #c15b52;
$warningColorLight: #faec91;
$warningColorLighter: #fdf8da;
$warningColorDarker: #c4af2b;
$successColorLighter: #b9ddb4;
$successColorLight: #91cb8a;
$successColorDarker: #77a771;

$teaserGradient: linear-gradient(200deg, $starColor, $red);
$backgroundGradient: linear-gradient(
  200deg,
  $secondaryColor 0%,
  $secondaryColor 40%,
  $primaryColor 100%
);

$fontDefault: Open Sans, sans-serif;
$fontSpecial: Hiruko Pro, sans-serif;

$fontSizeDefault: 1rem;
$fontSizeSmall: 0.857rem;
$fontSizeExtraSmall: 0.714rem;

$fontSizeHeadline1: 2.7rem;
$fontSizeHeadline2: 2rem;
$fontSizeHeadline3: 1.6rem;
$fontSizeHeadline4: 1rem;

$boxPadding: 32px 24px;
