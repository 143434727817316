@import '../../variables';

.TeaserTile {
  position: relative;
  height: 100%;
  &.yellow {
    .TeaserTile-backdrop {
      background: linear-gradient(
          85deg,
          rgba($red, 0.85) 0%,
          rgba($starColor, 0.85) 100%
        )
        0% 0% no-repeat padding-box;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
      background: $starColor;
    }
    .MuiPaper-root {
      border: 1px solid $red;
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
        background: $red;
      }
    }
  }

  &-image {
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -50deg,
      rgba(0, 70, 133, 0.85) 0%,
      rgba(0, 70, 133, 0.85) 20%,
      rgba(147, 192, 31, 0.85) 100%
    );
  }
  .MuiButton-label a {
    text-decoration: none;
    color: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &-main {
    position: relative;
    color: #fff;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    h2 {
      margin-bottom: 0.6em;
    }
  }
  &-content {
    width: 100%;
  }
  img {
    position: static;
    width: 350px;
    max-width: 100%;
    margin-bottom: 15px;
    @include lg {
      position: absolute;
      top: 20px;
      right: -30px;
    }
  }
}

.Home {
  .TeaserTile {
    img {
      @include lg {
        top: 60px;
      }
    }
  }
}
