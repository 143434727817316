@import "variables";
@import "typography";

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(fonts/roboto/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(fonts/roboto/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/material-icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}


html {
  height: 100%;
}

body,
#root {
  min-height: 100vh;
}

html,
body {
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #93c01f;
}

input:-internal-autofill-selected {
  padding-left: 8px;
  background: #ffffff22;
}

.logo img {
  height: 50px;
}

.MuiButton-root {
  border-radius: 50px !important;
  box-shadow: none;
  padding: 10px 25px;
  text-decoration: none;

  &:hover {
    box-shadow: none !important;
  }

  &.MuiButton-containedPrimary {
    color: #fff;
  }

  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none !important;
  }

  .MuiButton-label {
    font-family: Hiruko Pro, sans-serif;
    font-weight: 400;
    text-transform: none;
    text-decoration: none !important;

    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none !important;
    }
  }

  &.MuiButton-contained {
    box-shadow: none;
  }

  &.MuiButton-outlined {
    padding: 6px 15px;
    border: 2px dotted;
    @include lg {
      padding: 6px 35px;
    }
    &:hover {
      border: 2px dotted;
    }
    .MuiButton-label {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }

  &.MuiButton-contained {
    padding: 8px 20px;
  }

  &.MuiButton-sizeSmall {
    padding: 6px 10px;
    .MuiButton-label {
      font-weight: 300;
    }
  }

  &.MuiButton-outlinedPrimary {
    border-color: $primaryColor;
  }
}

.MuiInput-root.rounded-select {
  border: 1px solid $primaryColor;
  border-radius: 50px;
  background: none;
  outline: none;
  color: $lightTextColor;
  min-width: 150px;
  font-size: $fontSizeDefault;
  padding: 0;

  & > div {
    width: 100%;
  }

  .MuiSelect-select {
    font-size: $fontSizeDefault;
    color: $lightTextColor;
    padding: 14px 20px;
    padding-right: 40px;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  &:before,
  &:after {
    display: none;
  }

  & > svg {
    color: $lightTextColor;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.color-primary {
  color: $primaryColor;
}

.color-good {
  color: $primaryColor;
}

.color-bad {
  color: #c02b33;
}

table.MuiTable-root {
  // ---- fix for mui-datatable mobile view: https://github.com/gregnb/mui-datatables/issues/530
  [class*="MUIDataTableBodyRow-responsiveStacked-"] {
    display: flex;
    flex-wrap: wrap;
    td {
      flex-basis: 50%;
      display: inline-flex;
      align-items: center;
    }
  }
  @include md {
    [class*="MUIDataTableBodyRow-responsiveStacked-"] {
      display: table-row;
      td {
        display: table-cell;
        flex-basis: auto;
        &[class*="MUIDataTableBodyCell-cellHide-"] {
          display: none;
        }
      }
    }
  }
  // ----

  th,
  td {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: $fontSizeDefault;
    border: 0;
  }

  thead {
    th {
      border-bottom: 1px solid $primaryColor;
      font-weight: 300;
    }
  }
  tbody {
    tr:nth-child(2n + 1) {
      background: #f7f7f7;
    }
    border-bottom: 1px solid #e4e4e4;
  }
}

[class*="MUIDataTable-paper"].MuiPaper-elevation4 {
  box-shadow: none;
}

a.MuiLink-root {
  color: currentColor;
  text-decoration: none !important;
  border-bottom: 1px solid $primaryColor;
}

.MuiSlider-valueLabel {
  transform: scale(0.8) translateY(-10px) !important;
  & > span > span {
    font-size: 15px;
  }
}

a {
  cursor: pointer;
}

.logo {
  padding-left: 10px;
  @include lg {
    padding: 0;
  }
  a {
    border: 0 !important;
  }
}

.direction-xs-column-reverse {
  flex-direction: column-reverse;
}
.direction-xs-row-reverse {
  flex-direction: row-reverse;
}
.direction-lg-row {
  @include lg {
    flex-direction: row;
  }
}
.direction-lg-column {
  @include lg {
    flex-direction: column;
  }
}

/*
@media (max-width: #{$breakpoint-md}) {
  table.MuiTable-root th, table.MuiTable-root td {
    font-size: 12px !important;
    padding: 10px;
  }
}


@media (max-width: #{$breakpoint-sm}) {
  table.MuiTable-root th, table.MuiTable-root td {
    font-size: 7px !important;
    padding: 5px;
  }
}
*/

@media (max-width: #{$breakpoint-md}) {
  .MuiInput-root.rounded-select .MuiSelect-select {
    padding: 6px 10px;
    width: 80%;
  }
  .MuiInput-root.rounded-select {
    min-width: 70px;
  }
  .MuiInput-root.rounded-select > svg {
    top: 4px;
    right: 5px;
  }
}

table.MuiTable-root [class*="MUIDataTableBodyRow-responsiveStacked-"] td {
  flex-basis: 40%;
}

.steps-list li {
  margin-bottom: 20px;
}
.download-box {
  display: inline-flex;
  margin: 10px 0;
  padding: 15px;
  border: 1px solid $primaryColor;
  align-items: center;
}

.video-wrapper {
  padding: 20px 0;
  video {
    width: 100%;
    max-width: 533px;
    height: 100%;
    max-height: 300px;
  }
}
