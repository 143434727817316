@import "../../variables";

#login-menu {
  position: absolute;
  top: 55px;
  right: -48px;
  width: 300px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  padding: 20px;
  background: linear-gradient(
    0deg,
    $secondaryColor 0%,
    $secondaryColor 20%,
    $primaryColor 85%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  margin-top: 14px;

  @include md {
    right: 0px;
  }

  .MuiTextField-root {
    width: 100%;
  }

  & > * {
    margin-top: 10px;
  }
  .MuiFormLabel-root {
    color: #fff;
  }
  .MuiInputBase-root {
    color: #fff;
    &:before,
    &:after {
      display: none;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid $primaryColor;
    font-size: 1rem;
    margin-top: 5px;
  }

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 65px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background: $primaryColor;
  }

  .MuiButtonBase-root {
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}

.navigation {
  width: 100%;
  position: absolute;
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 40px 2%;
  z-index: 20;
  max-width: 1920px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  &.showHint {
    top: 30px;
  }

  nav {
    display: none;
    justify-content: center;
    align-items: center;
  }
  a {
    color: #fff;
    margin: 10px 15px;
    display: inline-block;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 1.15rem;
    font-family: $fontSpecial;
    text-decoration: none;
    &.active {
      border-bottom: 1px solid $primaryColor;
    }
  }
  .burger span {
    color: #fff;
  }

  .login {
    > a {
      margin: 0;
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
      padding: 10px;
      margin-left: 15px;
      min-width: auto;
      border: 2px dotted $primaryColor;
      @include md {
        min-width: 150px;
        padding: 4px 25px;
      }
      span {
        color: $primaryColor;
        font-weight: 300;
        font-size: 1.2rem;
      }
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
      padding: 10px;
      min-width: auto;
      margin: 0;
      margin-left: 15px;
      border: 2px solid transparent;
      @include md {
        min-width: 150px;
        padding: 4px 25px;
      }
      span {
        font-weight: 300;
        font-size: 1.2rem;
      }
    }
  }

  @include md {
    nav,
    .login {
      display: flex;
    }
    .burger {
      display: none;
    }
  }
  @include xl {
    padding: 40px 10%;
  }

  @media (max-width: #{$breakpoint-md}) {
    justify-content: flex-end;
    & > .logo {
      align-self: flex-start;
      flex: 1;
    }
    .login {
      margin-right: 6px;
    }
  }
}
