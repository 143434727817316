@import "../../variables";

.InputWithButton {
  width: 100%;
  &.MuiPaper-root {
    display: flex;
    justify-content: space-between;
    border-radius: 35px;
    padding: 1px;
    border: 1px solid $primaryColor;
    background: #ffffff3e;
    .MuiInputBase-root {
      padding: 0 15px;
      flex: 1;
      color: #fff;
      & input::placeholder {
        opacity: 1;
      }
    }
  }
}
