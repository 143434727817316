.MuiExpansionPanelSummary-root.MuiExpansionPanelSummary-root {
  padding: 0;
  border-top: 1px dashed #eee;
  .MuiExpansionPanelSummary-content {
    display: flex;
    align-items: center;
    .remove {
      display: none;
    }
    .MuiIcon-root {
      margin-right: 10px;
    }
    &.Mui-expanded {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
  }
}
.MuiCollapse-wrapperInner {
  .MuiExpansionPanelDetails-root {
    padding: 0 0 16px;
    display: block;
    img {
      width: 260px;
      margin: 0 5px;
    }
  }
}

.newFlag {
  font-size: 12px;
  color: #bb291e;
  margin-left: 5px;
  position: relative;
  display: inline-block;
  top: -12px;
  font-family: "Open Sans", sans-serif;
}
