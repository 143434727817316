@import "../../variables";

.Backlink {
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  margin: -30px 0 20px 20px;

  a {
    text-decoration: none;
    color: $lightTextColor;
  }

  svg {
    margin-right: 6px;
  }
}
