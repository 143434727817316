@import "../../variables";

.hero {
  position: relative;
  &.sm {
    padding: 80px 0 20px;
    @include lg {
      padding: 100px 0 25px;
    }
  }
  &.md {
    padding: 100px 0 30px;
    @include lg {
      padding: 150px 0 50px;
    }
  }
  &.lg {
    padding: 120px 0 40px;
    @include lg {
      padding: 200px 0 75px;
    }
  }
  &.home {
    padding: 140px 0 80px;

    @include lg {
      padding: 400px 0 180px;
    }

    .digitalChampionsAward {
      display: none;

      @include lg {
        position: absolute;
        right: 75px;
        bottom: 104px;
        display: block;
      }
    }

    .overlay {
      background: linear-gradient(
        220deg,
        rgba(0, 70, 133, 0.8) 0%,
        rgba(0, 70, 133, 0.8) 40%,
        rgba(147, 192, 31, 0.8) 100%
      );
    }
  }

  .digitalChampionsAward {
    display: none;
  }

  h1 {
    color: #fff;
    font-weight: 400;
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-size: cover;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      145deg,
      rgba(0, 70, 133, 0.8) 0%,
      rgba(0, 70, 133, 0.8) 40%,
      rgba(147, 192, 31, 0.8) 100%
    );
  }
}
